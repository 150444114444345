'use strict';

var base = require('base/components/clientSideValidation');

function specialCharactersValidation(currentObj) {
    var pattern = currentObj.attr('pattern');
    var currentValue = currentObj.val();
    var validationMessage = currentObj.data('pattern-mismatch');
    if (currentObj.hasClass('shippingLastName') || currentObj.hasClass('billingLastName')) {
        if (currentValue.length === 1) {
            validationMessage = currentObj.data('pattern-length-mismatch');
            return validationMessage;
        }
        pattern = pattern.replace('{2,}', '');
    }
    if ((pattern && currentValue && validationMessage) &&
        (currentObj.hasClass('shippingFirstName') || currentObj.hasClass('billingFirstName') ||
        currentObj.hasClass('shippingLastName') || currentObj.hasClass('billingLastName') ||
        currentObj.hasClass('shippingAddressOne') || currentObj.hasClass('billingAddressOne') ||
        currentObj.hasClass('shippingAddressTwo') || currentObj.hasClass('billingAddressTwo')
        )) {
        if (currentObj.hasClass('specialcharcheck')) {
            return validationMessage;
        }
        pattern = new RegExp(pattern);
        var nonMatchingChars = currentValue.split('').filter(function (char) {
            return !pattern.test(char);
        });
        nonMatchingChars = nonMatchingChars.filter(function (char, index) {
            return nonMatchingChars.indexOf(char) === index;
        });
        return validationMessage + '  ' + nonMatchingChars.join(',');
    }
    return validationMessage;
}
/**
* Validate whole form. Requires `this` to be set to form object
* @param {jQuery.event} event - Event to be canceled if form is invalid on blur.
* @returns {boolean} - Flag to indicate if form is valid on blur
*/
function validateFormBlur(event) {
    var valid = true;
    if (this.checkValidity && !this.checkValidity()) {
        // safari
        valid = false;
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
        if (!this.validity.valid) {
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                $(this).removeClass('has-success');
                if ($(this).closest('.data-checkout-stage').data('checkout-stage') === 'payment') {
                    $('button.submit-payment').prop('disabled', 'disabled');
                }
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    var currentObj = $(this);
                    validationMessage = specialCharactersValidation(currentObj);
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group').find('.invalid-feedback')
                    .text(validationMessage);
            }
        }
    } else {
        $(this).removeClass('is-invalid');
        if ($(this).val().length > 0) {
            $(this).addClass('has-success');
        } else {
            $(this).removeClass('has-success');
        }
        $(this).closest('div.form-group').find('.invalid-feedback').text('');
    }
    return valid;
}

module.exports = {
    invalid: base.invalid,

    submit: base.submit,

    buttonClick: base.buttonClick,

    functions: base.functions,

    focusoutValidation: function () {
        $('body').on('blur', 'input, select', function (e) {
            var currentObj = $(this);
            if (currentObj.attr('id') === 'registration-form-email' || currentObj.hasClass('js-shipping-email')) {
                var value = currentObj.val();
                var emailRegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
                var isValid = emailRegEx.test(value);
                if (value && isValid) {
                    currentObj.addClass('has-success').removeClass('is-invalid');
                    currentObj.closest('.form-group').find('.invalid-feedback').html('');
                } else if (value === null || value === '') {
                    currentObj.removeClass('has-success').addClass('is-invalid');
                    currentObj.closest('.form-group').find('.invalid-feedback').html(currentObj.data('missing-error'));
                } else {
                    currentObj.removeClass('has-success').addClass('is-invalid');
                    currentObj.closest('.form-group').find('.invalid-feedback').html(currentObj.data('pattern-mismatch'));
                }
            } else if (currentObj.hasClass('js-reg-form-password-na')) {
                if ($('.pw-errors').is(':visible')) {
                    $('.pw-errors div:not(.valid)').addClass('pwerror');
                } else {
                    validateFormBlur.call(this, e);
                }

                if ($(this).val().length > 0) {
                    $('.pw-errors .pw-requirements-not-met').show();
                } else {
                    $('.pw-errors .pw-requirements-not-met').hide();
                }
            } else {
                validateFormBlur.call(this, e);
            }

            $('.shippingCountry').find('option').each(function (index, element) {
                if (element.value == 'IE') {
                    if (currentObj.hasClass('shippingZipCode') && currentObj.val() === '') {
                        currentObj.val('0000');
                        currentObj.removeClass('is-invalid');
                        currentObj.addClass('has-success');
                        currentObj.addClass('input-value-present');
                        currentObj.closest('div.form-group').find('.invalid-feedback').text('');
                        var valid = $('form#dwfrm_shipping')[0].checkValidity();
                        if (valid) {
                            $('form#dwfrm_shipping').closest('.data-checkout-stage').find('button.submit-shipping').prop('disabled', false);
                        }
                    }
                }
            });
        });

        $('body').on('click', 'input#privacynotice', function () {
            validateFormBlur.call(this);
        });
    },

    showPassword: function () {
        // Show / Hide Password
        $('.js-show-password .show-password, .js-show-password .hide-password').on('click', function () {
            var passwordId = $(this).parents('.pw-container').find('input').attr('id');
            if ($(this).hasClass('show-password')) {
                $('#' + passwordId).attr('type', 'text');
                $(this).parent().find('.show-password').hide();
                $(this).parent().find('.hide-password').show();
            } else {
                $('#' + passwordId).attr('type', 'password');
                $(this).parent().find('.hide-password').hide();
                $(this).parent().find('.show-password').show();
            }
        });
    },

    validateFormFill: function () {
        $('body').on('validateFormFill', function () {
            $('.form-group .invalid-feedback').text('');
            $('.form-group .is-invalid').removeClass('is-invalid');
            $('input, select').each(function () {
                if ($(this).val()) {
                    validateFormBlur.call(this);
                }
            });
        });
    },

    validatePassword: function () {
        $('body').on('keyup', '.js-reg-form-password', function () {
            var isValidPassword = true;
            var value = $(this).val();
            if ($(this).hasClass('js-reg-form-password-na')) {
                if (value.length < 8 || value.length > 20) { // 8 - 20 characters
                    isValidPassword = false;
                    $('.invalid-password-error').find('.length-error').removeClass('valid');
                } else {
                    $('.invalid-password-error').find('.length-error').addClass('valid').removeClass('pwerror');
                }

                if (/^[a-zA-Z0-9 ]*$/.test(value)) { // symbol
                    isValidPassword = false;
                    $('.invalid-password-error').find('.symbol-error').removeClass('valid');
                } else {
                    $('.invalid-password-error').find('.symbol-error').addClass('valid').removeClass('pwerror');
                }

                if (!/\d/.test(value)) { // number
                    isValidPassword = false;
                    $('.invalid-password-error').find('.number-error').removeClass('valid');
                } else {
                    $('.invalid-password-error').find('.number-error').addClass('valid').removeClass('pwerror');
                }

                if (!/[A-Z]/.test(value)) { // uppercase letters
                    isValidPassword = false;
                    $('.invalid-password-error').find('.uppercase-error').removeClass('valid');
                } else {
                    $('.invalid-password-error').find('.uppercase-error').addClass('valid').removeClass('pwerror');
                }

                if (!/[a-z]/.test(value)) { // lowercase letters
                    isValidPassword = false;
                    $('.invalid-password-error').find('.lowercase-error').removeClass('valid');
                } else {
                    $('.invalid-password-error').find('.lowercase-error').addClass('valid').removeClass('pwerror');
                }

                $(this).parents('.pw-container').find('.show-password').show();

                if (!isValidPassword) {
                    $(this).parents('.form-group').find('.pw-errors').slideDown();
                } else {
                    $(this).parents('.form-group').find('.pw-errors').slideUp();
                }
            } else {
                if (value.length < 8 || value.length > 20) { // 8 - 20 characters
                    isValidPassword = false;
                    $('.invalid-password-error').find('.length-error').fadeIn();
                } else {
                    $('.invalid-password-error').find('.length-error').fadeOut();
                }
                if (!/\d/.test(value) || /^[a-zA-Z0-9 ]*$/.test(value)) { // a number or symbol
                    isValidPassword = false;
                    $('.invalid-password-error').find('.symbol-error').fadeIn();
                } else {
                    $('.invalid-password-error').find('.symbol-error').fadeOut();
                }
                if (!/[a-z]/.test(value) || !/[A-Z]/.test(value)) { // both upper and lower case letters
                    isValidPassword = false;
                    $('.invalid-password-error').find('.letter-error').fadeIn();
                } else {
                    $('.invalid-password-error').find('.letter-error').fadeOut();
                }
            }
            return isValidPassword;
        });
    },

    checkPasswordMatch: function () {
        $('body').on('blur change', '.js-reg-form-pwd-confirm', function () {
            var password = $('.js-reg-form-password').val();
            var confirmPassword = $('.js-reg-form-pwd-confirm').val();

            if ($(this).val()) {
                if (password != confirmPassword) {
                    $(this).addClass('is-invalid');
                    $(this).removeClass('has-success');
                    $(this).parents('.form-group').find('.invalid-feedback')
                        .text($(this).data('passmatch-error'));
                } else {
                    $(this).removeClass('is-invalid');
                    $(this).addClass('has-success');
                    $(this).closest('div.form-group').find('.invalid-feedback').text('');
                }
            }
        });
    },

    showPasswordConfirm: function () {
        $('body').on('keyup', '.js-reg-form-pwd-confirm, .js-sign-in-form-pwd', function () {
            $(this).parents('.pw-container').find('.show-password').show();
        });
    },

    inputFieldEmptyCheck: function () {
        var $toggleSwitch = $('.shipping-form').find('.js-manualEnter-option');
        $('body').on('input change', 'input:visible, select:visible', function () {
            $('input:visible, select:visible').each(function () {
                if ($(this).val() && $(this).val().length > 0) {
                    $(this).addClass('input-value-present');
                    var $hiddenFields = $('.shipping-form').find('.detailed-shipping-info-formfields');
                    if ($hiddenFields.hasClass('d-none') && $hiddenFields.find('input.is-invalid').length && $('.shipping-form').is(':visible')) {
                        $('.shipping-form').find('.js-manualEnter-option').trigger('click');
                        $hiddenFields.find('input, select').each(function () {
                            if ($(this).hasClass('valid')) {
                                $(this).closest('div.form-group').find('.invalid-feedback').text('');
                            }
                            $(this).removeClass('is-invalid valid');
                            if ($(this).val().length > 0) {
                                $(this).addClass('has-success valid');
                            } else {
                                $(this).removeClass('has-success');
                                $(this).addClass('is-invalid');
                            }
                        });
                    }
                } else {
                    $(this).removeClass('input-value-present');
                }
                if ($(this).hasClass('shippingAddressOne')) {
                    $(this).closest('.shipping-info-holder').find('input, select').each(function () {
                        if ($(this).attr('edq-metadata')) {
                            var edqResponse = JSON.parse($(this).attr('edq-metadata'));
                            var address = edqResponse.address;
                            var postalCodeEmpty = false;
                            var isIECountry = false;
                            for (var i = 0; i < address.length; i++) {
                                if (address[i].postalCode === '') {
                                    postalCodeEmpty = true;
                                } else if (address[i].country === 'IRELAND') {
                                    isIECountry = true;
                                }
                            }
                            if (postalCodeEmpty && isIECountry) {
                                $('.shippingZipCode').val('0000');
                            }
                        }
                        if ($(this).val()) {
                            validateFormBlur.call(this);
                        }
                        if (!$toggleSwitch.closest('.shipping-address-block').find('.detailed-shipping-info-formfields').hasClass('d-none')) {
                            $toggleSwitch.hide();
                        }
                    });
                }
            });
        });
    }
};
